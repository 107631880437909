<script>
import { issue } from '@/api/beijing28'
import { caizhong } from "@/api/home";
import { games, } from '@/CONF.js';

export default {
    name: 'GameList',
    components:{
        Header: () => import('components/base/Header'),
    },
    data() {
        return {
            ossPrefix: "//cpwap."+process.env.VUE_APP_PROD_DOMAIN+'/',
            folder: '/home/game-list/',
            types:['sc','ssc','pc28','lhc','fiveselect','klsf','fastthree','xync','3d','cp','kl8','ffc','xy5'],
            games:games(),
            header:{
                mainTitle: getWord('jingdiancai'),
            },
            countdownTimer:null,
            gameCodenames:null,
            currentCount:0,
            retriveCurrentDrawingRemainFrequency:2000,
            wannengName:null,
        }
    },
    props: [
        
    ],
    inject:[
        
    ],
    methods: {
        routerLink(item) {            
            if (item.codename==='xyft' || item.codename==='bjsc' || item.codename==='cqssc' || (item.codename.indexOf('28')>-1&&item.codename!=='pc28one') ) {
                this.$router.push({
                    path:'beijing28/room_list',
                    query: {
                        type: item.codename,
                        name: item.name
                    }
                })
            }else {
                this.$router.push({
                    path:'/t',
                    query: {
                        type: item.codename,
                        name: item.name
                    }
                })                    
            }            
        },
        retriveCurrentDrawingRemain(gameType,_item) {
            var currentItem=null
            if (gameType instanceof Array) {
                currentItem=gameType[this.currentCount]
            }else {
                currentItem=gameType
            }
            if (this.currentCount===gameType.length) return false;
            var that=this;

            var currentObject=[];

            (that.games.reduce((acc, value) => acc.concat(value), [])).forEach(item=>{

                if (!(_item&&_item.timer)) {
                    var related=item.list.filter(item=>{
                        return item.codename===currentItem
                    })
                    if (related.length>0) {
                        related.forEach(item=>{
                            currentObject.push(item)  
                        })
                    }
                }else{
                    var related=item.list.filter(item=>{
                        return item===_item
                    })
                    if (related.length===1) {
                        currentObject=related
                    }
                }
            })

            var result=null;
            if (this.$store.state.currentDrawing && this.$store.state.currentDrawing[currentItem]) {
                this.retriveCurrentDrawingRemainFrequency=100;
                result=this.$store.state.currentDrawing[currentItem].time_diff;    
                // console.log(result)

                currentObject.forEach(item=>{
                    item.countdown=result;
                    item.timer=setInterval(function(){
                        if (item.countdown===0) {
                            if (item.timer!==null) {
                                window.clearInterval(item.timer)
                            }
                            setTimeout(function(){
                                that.retriveCurrentDrawingRemain(item.codename,item)
                            },6000);
                        }else {
                            --item.countdown
                        }
                    },1000)
                })
                if (gameType instanceof Array) {
                    that.currentCount++;

                    that.countdownTimer=setTimeout(function(){
                        that.retriveCurrentDrawingRemain(gameType)
                    },that.retriveCurrentDrawingRemainFrequency)
                }else {
                    return false;
                }
            }else {
                return new Promise(function (resolve,reject) {
                    issue(currentItem).then(result => {
                        if (result.data.code === 'SUCCESS' && result.data.result.status===1) {
                            var result = result.data.result.time_diff;
                            resolve(result)
                        }else {
                            resolve(null)
                        }
                    })
                })
                .then(function (result) {
                    if (result!==null) {
                        currentObject.forEach(item=>{
                            item.countdown=result;
                            item.timer=setInterval(function(){
                                if (item.countdown===0) {
                                    if (item.timer!==null) {
                                        window.clearInterval(item.timer)
                                    }
                                    that.retriveCurrentDrawingRemain(item.codename,item)                                    
                                }else {
                                    --item.countdown
                                }
                            },1000)
                        })
                    }
                    if (gameType instanceof Array) {
                        that.currentCount++;

                        that.countdownTimer=setTimeout(function(){
                            that.retriveCurrentDrawingRemain(gameType)
                        },that.retriveCurrentDrawingRemainFrequency)
                    }else {
                        return false;
                    }
                
                });
            }
        },
        formatCountdownTimer(timeStamp){
            if (isNaN(timeStamp)) {
                return 'ERROR: invalid countdown format'
            }else if (timeStamp<0) {
                return getWord('handicap_renew')
            } else {
                var hours=parseInt(timeStamp/3600)
                var minutes=parseInt(timeStamp%3600/60)
                var seconds=parseInt(timeStamp%3600%60)
                return (hours>9?hours:'0'+hours)+':'+(minutes>9?minutes:'0'+minutes)+':'+(seconds>9?seconds:'0'+seconds)
            }
        },
    },
    mounted() {
        caizhong().then(result => {
            if (result.data.code == "SUCCESS") {
                var _formatedHotGames=result.data.result.hot_game;
                var games=result.data.result.numbers.children;

                var _formatedGames=[];
                Object.keys(games).forEach(item=>{
                    games[item].children.forEach(item=>{
                        if (item.type==='wanneng') {
                            this.wannengName=item.name
                        }
                        _formatedGames.push(item)
                    })
                })
                _formatedGames.forEach(item=>{                    
                    item.img = this.ossPrefix + this._TEMPLATE + this.folder + item.type + '.png';
                })
                console.log(this.ossPrefix)

                _formatedHotGames.forEach(item=>{                    
                    item.img = this.ossPrefix + this._TEMPLATE + this.folder + item.type + '.png';
                })

                var related=this.games.filter(item=>{
                    return item.type==='hot'
                })[0]

                related.list=[];
                _formatedHotGames.forEach(item=>{
                    related.list.push({
                        codename:item.type,
                        name:item.name,
                        countdown:null,
                        timer:null,
                    })
                })
                this.types.forEach(_item=>{
                    related=this.games.filter(item=>{
                        return item.type===_item
                    })[0]
                    related.list=[];
                    var relatedGames=_formatedGames.filter(item=>{
                        return item.sub_type===_item
                    })
                    relatedGames.forEach(item=>{
                        related.list.push({
                            codename:item.type,
                            name:item.name,
                            countdown:null,
                            timer:null,
                        })
                    })
                })

                //data format done
                this.CustomScroll(this.$refs.gameList.id);

                var codenameList=[];
                (this.games.reduce((acc, value) => acc.concat(value), [])).forEach(item=>{
                    
                    if (item.type==='4d' && this.wannengName===null) {
                        item.list=[];
                    }
                    item.list.forEach(item=>{
                        if (item.codename==='wanneng') {
                            item.name=this.wannengName
                        }
                        codenameList.push(item.codename)
                    })
                })
                this.gameCodenames=[...new Set(codenameList)];
                this.retriveCurrentDrawingRemain(this.gameCodenames);
            }else {
                app.Mint.Toast(result.data.msg)
            }
        });
    },
    computed:{
        currentDrawing() {
            return this.$store.state.currentDrawing
        },
        getWanneng(){
            return this.wannengName
        }
    },
    watch:{
        
    },
    beforeDestroy(){
        (this.games.reduce((acc, value) => acc.concat(value), [])).forEach(item=>{
            item.list.forEach(item=>{
                if (item.timer!==null) {
                    window.clearInterval(item.timer)
                    item.timer=null;
                }
            })
        })
        if (this.countdownTimer!==null) {
            window.clearTimeout(this.countdownTimer)
        }
    }
};
</script>
<template>    
    <section id="game-list" ref="gameList" :class="_TEMPLATE">
        <Header
            :mainTitle="header.mainTitle"
            :backHome=true
        />
        <div class="inner">
            <div class="block" v-for="item in games" v-if="item.list.length>0">
                <div class="left">
                    <h4 :style="{'--data-color':item.color}"><b>{{item.alias1}}</b>{{item.alias2}}</h4>
                    <img :src="item.icon" />
                </div>
                <ul>
                    <li v-for="_item in item.list" @click="routerLink(_item)">
                        <b>{{_item.name}}</b>
                        <span :class="{number:_item.countdown}">{{_item.countdown?formatCountdownTimer(_item.countdown):getWord('wait_for_handicap_renew')}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>
<style lang='scss' scoped>
@import "@@/assets/style/_variables";
    section#game-list {
        background-color: #F5F5F9;
        overflow:auto;

        .block {
            background-color: #ffffff;
            min-height: 2rem;
            display: flex;
            padding: .2rem .15rem;
            margin-bottom: .1rem;

            .left {
                text-align: center;                
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-basis: 1.5rem;
                flex-grow: 0;
                flex-shrink: 0;

                h4 {
                    font-size: .3rem;
                    color: var(--data-color);                    

                    b {
                        font-weight: normal;
                        font-size: .4rem;
                        margin-right: .15rem;
                        word-break: break-all;
                    }
                }

                > img {
                    width: .88rem;
                    margin: 0 auto;
                    position: relative;
                    top: -.1rem;
                }
            }

            ul {
                list-style: none;

                li {
                    width: 1.8rem;
                    background-color: #F2F8FF;
                    margin-right: .1rem;
                    text-align: center;
                    padding: .18rem 0;
                    margin-bottom: .1rem;
                    float: left;
                    overflow: hidden;
                    height: 0.6rem;

                    b {
                        white-space: nowrap;
                    }

                    span {
                        white-space: nowrap;
                        display: block;
                        color: #41A441;

                        &.number {
                            color: $TEMPLATE2-theme-color;
                        }
                    }               
                }
            }
        }
    }
</style>